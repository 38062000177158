import { GOOGLE_SIGNUP_KEY } from "./constants";

type GoogleSignUpState = {
    isSignUp: boolean;
    termsAndPrivacyAccepted?: boolean;
    referralCode?: string;
};

export const setGoogleSignUpState = (googleSignUpState: GoogleSignUpState) =>
    localStorage.setItem(GOOGLE_SIGNUP_KEY, JSON.stringify(googleSignUpState));

export const getGoogleSignUpState = (): GoogleSignUpState => {
    const googleSignUpState = localStorage.getItem(GOOGLE_SIGNUP_KEY);
    if (!googleSignUpState) return { isSignUp: false };

    return JSON.parse(googleSignUpState);
};

export const clearGoogleSignUpState = () => localStorage.removeItem(GOOGLE_SIGNUP_KEY);
