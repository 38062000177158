import { zodResolver } from "@hookform/resolvers/zod";
import { type FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import {
    Button,
    CmsError,
    ControlledFormCheckbox,
    DownloadLink,
    Heading,
    Input,
    Link,
    PublicLayout,
    SecretInput,
    Separator,
} from "src/components";
import { useAppConfigQuery } from "src/config";
import { Routes } from "src/routes";
import {
    isCmsErrorResponseWithMessage,
    removeAuthToken,
    useClearReferralCodeMutation,
    useGetReferralCodeQuery,
    useLazyRegisterEhubUserQuery,
    useRegistrationMutation,
} from "src/serverApi";
import { Trans, useTranslation } from "src/translations";
import { logGtmEvent, passwordSchema } from "src/utils";
import { GoogleButton } from "../google";
import { logger } from "./logger";
import { SignUpSuccess } from "./SignUpSuccess";
import { isEhubReferralCode, useSearchParamReferralCode } from "./utils";

export const SignUpPage: FC = () => {
    const { t, i18n } = useTranslation();

    const [registration, { isLoading, isSuccess, isError, error }] = useRegistrationMutation();

    const { data: appConfig, refetch: refetchAppConfig } = useAppConfigQuery();

    // Manually refetch app config documents on language change
    // biome-ignore lint/correctness/useExhaustiveDependencies(i18n.language): <explanation>
    useEffect(() => {
        refetchAppConfig();
    }, [refetchAppConfig, i18n.language]);

    const termsAndConditionsLink = appConfig?.termsAndConditions?.url;
    const termsAndConditionsText = appConfig?.termsAndConditions?.caption;
    const privacyPolicyLink = appConfig?.privacyPolicy?.url;
    const privacyPolicyText = appConfig?.privacyPolicy?.caption;

    const SignUpSchema = z.object({
        email: z.string().min(1, t("common.required.email")).email(t("signUp.field.error.email")),
        password: passwordSchema(t),
        referralCode: z.string().optional(),
        terms: z.boolean().refine(Boolean),
        privacy: z.boolean().refine(Boolean),
    });

    type FormFields = z.infer<typeof SignUpSchema>;

    const {
        handleSubmit,
        register,
        getFieldState,
        getValues,
        setValue,
        resetField,
        control,
        watch,
        trigger,
        formState: { errors },
    } = useForm<FormFields>({
        resolver: zodResolver(SignUpSchema),
        defaultValues: {
            email: "",
            password: "",
            terms: false,
            privacy: false,
        },
    });

    const referralCodeFromParam = useSearchParamReferralCode();
    const { data: referralCodeFromServer } = useGetReferralCodeQuery();
    const [clearReferralCodeMutation] = useClearReferralCodeMutation();
    const [registerEhubUser] = useLazyRegisterEhubUserQuery();
    const [isReferralCodeFieldDisabled, setIsReferralCodeFieldDisabled] = useState(!!referralCodeFromParam);

    useEffect(() => {
        const referralCode = referralCodeFromParam ?? referralCodeFromServer;
        if (referralCode) {
            setValue("referralCode", isEhubReferralCode(referralCode) ? "eHub" : referralCode);
            setIsReferralCodeFieldDisabled(true);
        }
    }, [referralCodeFromParam, referralCodeFromServer, setValue]);

    const clearReferralCode = () => {
        resetField("referralCode");
        setIsReferralCodeFieldDisabled(false);
    };

    const onSubmit = async (data: FormFields) => {
        try {
            removeAuthToken();
            const { privacy, terms, email, ...registrationData } = data;
            const { user } = await registration({
                username: email,
                email,
                termsAndPrivacyAccepted: terms && privacy,
                ...registrationData,
            }).unwrap();
            if (referralCodeFromServer) {
                clearReferralCodeMutation();
            }
            const referralCode = referralCodeFromParam ?? referralCodeFromServer;
            if (user?.id && isEhubReferralCode(referralCode)) {
                registerEhubUser({ userId: user.id, visitId: referralCode });
            }
            logGtmEvent({ event: "user_registered", mail: email });
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    if (isSuccess) return <SignUpSuccess email={getValues("email")} />;

    return (
        <PublicLayout title={t("signUp.title")}>
            <Heading level={6} className="mb-8 text-center">
                {t("signUp.text")}
            </Heading>
            <GoogleButton signUp referralCode={watch("referralCode")} triggerValidation={() => trigger(["terms", "privacy"])} />
            <form id="Registration" onSubmit={handleSubmit(onSubmit)} className="w-full space-y-[40px]">
                {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
                <div className="space-y-6">
                    <Separator />
                    <Input label={t("signUp.label.email")} max="70" {...register("email")} error={errors.email?.message} fullWidth />
                    <SecretInput label={t("signUp.label.password")} {...register("password")} error={errors.password?.message} />
                    <div className="flex items-end gap-1">
                        <Input
                            label={t("signUp.label.referralCode")}
                            {...register("referralCode")}
                            disabled={isReferralCodeFieldDisabled}
                            error={errors.referralCode?.message}
                            fullWidth
                        />
                        {isReferralCodeFieldDisabled && (
                            <Button className="px-2.5 py-1.5" kind="outline" onClick={clearReferralCode}>
                                {t("signUp.label.clearReferralCode")}
                            </Button>
                        )}
                    </div>
                    <div className="flex flex-col gap-3">
                        {termsAndConditionsLink && (
                            <Controller
                                name="terms"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label className="flex items-center gap-2">
                                            <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                            <span>
                                                <Trans
                                                    i18nKey="signUp.agree.text"
                                                    components={{
                                                        downloadLink: <DownloadLink className="text-base" href={termsAndConditionsLink} />,
                                                    }}
                                                    values={{ linkText: termsAndConditionsText }}
                                                />
                                            </span>
                                        </label>
                                        {errors.terms && <span className="text-sm text-error">{t("signUp.agree.error")}</span>}
                                    </>
                                )}
                            />
                        )}
                        {privacyPolicyLink && (
                            <Controller
                                name="privacy"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label className="flex items-center gap-2">
                                            <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                            <span>
                                                <Trans
                                                    i18nKey="signUp.agree.text"
                                                    components={{
                                                        downloadLink: <DownloadLink className="text-base" href={privacyPolicyLink} />,
                                                    }}
                                                    values={{ linkText: privacyPolicyText }}
                                                />
                                            </span>
                                        </label>
                                        {errors.privacy && <span className="text-sm text-error">{t("signUp.agree.error")}</span>}
                                    </>
                                )}
                            />
                        )}
                    </div>
                </div>

                <div className="flex flex-col gap-5">
                    <Button
                        disabled={getFieldState("email").invalid || getFieldState("password").invalid}
                        isLoading={isLoading}
                        type="submit"
                        fullWidth
                    >
                        {t("signUp.button.submit")}
                    </Button>
                    <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
                        <span className="text-sm">{t("signUp.login.label")}</span>
                        <Link href={Routes.signIn.path}>{t("signUp.login.link")}</Link>
                    </div>
                </div>
            </form>
        </PublicLayout>
    );
};
