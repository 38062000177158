import { t } from "i18next";
import type { FC } from "react";

import { ButtonWithIcon } from "src/components";
import { GoogleIcon } from "src/icons";
import { getCmsUrl } from "src/serverApi";
import { logGtmEvent } from "src/utils";
import { setGoogleSignUpState } from "./utils";

type GoogleButtonProps = Readonly<{
    signUp?: boolean;
    referralCode?: string;
    triggerValidation?: () => Promise<boolean>;
}>;

export const GoogleButton: FC<GoogleButtonProps> = ({ referralCode, triggerValidation, signUp = false }) => {
    const handleClick = async () => {
        const termsAndPrivacyAccepted = await triggerValidation?.();
        if (triggerValidation && !termsAndPrivacyAccepted) return;

        if (signUp) {
            setGoogleSignUpState({ isSignUp: true, referralCode, termsAndPrivacyAccepted });
        }

        logGtmEvent({ event: `user_google_${signUp ? "registration" : "login"}_triggered` });
        window.location.href = getCmsUrl("/api/connect/google");
    };

    return (
        <ButtonWithIcon icon={<GoogleIcon />} className="w-full bg-black hover:bg-black" onClick={handleClick}>
            {t("google.button.title")}
        </ButtonWithIcon>
    );
};
