import { type FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CmsError, Loading } from "src/components";
import { Routes } from "src/routes";
import { setAuthToken, useClearReferralCodeMutation, usePublicGoogleJwtQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logGtmEvent } from "src/utils";
import { useUpdateProfileUser } from "../utils";
import { clearGoogleSignUpState, getGoogleSignUpState } from "./utils";

export const GoogleCallbackPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { next, ...authParams } = Object.fromEntries(searchParams.entries()) ?? {};

    const { data: googleJwt, isLoading, isError, error } = usePublicGoogleJwtQuery(authParams);
    const [clearReferralCode] = useClearReferralCodeMutation();
    const updateUserProfile = useUpdateProfileUser();

    const { referralCode, isSignUp, termsAndPrivacyAccepted } = getGoogleSignUpState();

    useEffect(() => {
        if (!isLoading && googleJwt) {
            const { jwt } = googleJwt;
            if (jwt) {
                setAuthToken(jwt);
                updateUserProfile({ referredBy: referralCode, termsAndPrivacyAccepted }).then(() => {
                    logGtmEvent({ event: `user_google_${isSignUp ? "registered" : "logged_in"}` });
                    clearGoogleSignUpState();
                    clearReferralCode();
                    navigate(next ?? Routes.projects.path);
                });
            }
        }
    }, [isLoading, googleJwt, updateUserProfile, navigate, next, clearReferralCode, referralCode, isSignUp, termsAndPrivacyAccepted]);

    return isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : <Loading isFullPage />;
};
