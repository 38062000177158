import { useCallback } from "react";

import { useLazyUserQuery, useUpdateUserProfileMutation } from "src/serverApi";
import { Languages, useTranslation } from "src/translations";
import { type UserProfile, UserProfileCurrencyEnum1, type UserProfileLanguageEnum1 } from "src/types";
import { getHubspotCookie } from "src/utils";

export const useUpdateProfileUser = () => {
    const [getUser] = useLazyUserQuery();
    const [updateProfile] = useUpdateUserProfileMutation();
    const { i18n } = useTranslation();

    return useCallback(
        async (partialUserProfile?: Partial<UserProfile>) => {
            const user = await getUser().unwrap();
            if (user.userProfile?.documentId) {
                const defaultCurrency = i18n.language === Languages.cs ? UserProfileCurrencyEnum1.CZK : UserProfileCurrencyEnum1.EUR;
                const hubspotCookieId = getHubspotCookie();
                await updateProfile({
                    profileDocumentId: user.userProfile.documentId,
                    data: {
                        lastLogin: new Date().toISOString(),
                        currency: user.userProfile.currency ?? defaultCurrency,
                        language: i18n.language as UserProfileLanguageEnum1,
                        hsCookieId: hubspotCookieId === "" ? undefined : hubspotCookieId,
                        ...partialUserProfile,
                    },
                });
            }
        },
        [getUser, i18n.language, updateProfile],
    );
};
